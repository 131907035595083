const classCard = document.querySelectorAll('.class-card');

const setupResponsive = () => {
  if (classCard) {
    classCard.forEach((card) => {
      const toggler = card.querySelector('.headings');

      toggler.addEventListener('click', () => {
        const isOpen = card.getAttribute('aria-expanded') === 'true';

        if (isOpen) {
          card.setAttribute('aria-expanded', 'false');
        } else {
          card.setAttribute('aria-expanded', 'true');

          classCard.forEach((item) => {
            item !== card && item.setAttribute('aria-expanded', 'false');
          });
        }
      });
    });
  }
};

window.innerWidth <= 992 && setupResponsive();

window.addEventListener('resize', () => {
  window.innerWidth <= 992 && setupResponsive();
});
